import logo from './logo.svg';
import './App.css';
import React, { useState } from "react";

import { Container, Typography, Grid, ThemeProvider, useTheme, Accordion, AccordionSummary, AccordionDetails, Menu, MenuItem, IconButton, Box } from '@mui/material';
import InertiaChange from './InertialChange.png';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Snackbar from '@mui/material/Snackbar';

function App() {
  //styling
  const theme = useTheme();

  //Accordion
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
    setSnackbarOpen(true); // Open the Snackbar
  };

  const handleClose2 = () => {
    // window.location.href = "https://docs.google.com/forms/d/1C7mOtoNi7kMwx-lKZH0DDliVYfSBwggl4P1bMgiYB-0/edit?pli=1"
    window.location.href = "https://forms.gle/92ycEFb94KcKsvAn6"

    setAnchorEl(null);
  };

  const handleClose3 = () => {
    setAnchorEl(null);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);


  return (
    <ThemeProvider theme={theme}>
      <Container>

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sx={{ padding: theme.spacing(2) }}>
            <div className="App">
              <header className="App-header">

                {/* Menu */}
                <IconButton
                  color="inherit"
                  onClick={handleClick}
                  sx={{ marginLeft: "auto" }}
                >
                  <AccountCircleIcon />
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose3}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem onClick={handleClose1}>Sign In</MenuItem>
                  <MenuItem onClick={handleClose2}>Sign Up</MenuItem>
                </Menu>


                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={3000}
                  onClose={() => setSnackbarOpen(false)}
                  message="This feature is not available yet."
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                />

                {/* Title */}
                <Typography variant='h5' sx={{ color: '#61dafb' }} >
                  <b>myBernoulli</b>
                </Typography>

                <img src={logo} className="App-logo" alt="logo" />

                <Typography variant='body2'
                  sx={{
                    color: '#61dafb',
                    padding: theme.spacing(1)
                  }}
                >
                  <b> A Goodwill Collaboration Platform for Continuous Improvement and Ground Up Initiatives</b>
                </Typography>

                {/* <Typography variant="body1" sx={{ marginTop: theme.spacing(1), padding: theme.spacing(1), wordWrap: 'break-word' }}  >
                  <Typography variant="body2" component="span">
                    It all started with a question:&nbsp;
                  </Typography>
                  <b style={{ color: '#61dafb' }} >"Why can't there be a better way?"</b>
                </Typography> */}


                {/* 

                <img
                  src={InertiaChange}
                  alt="Img"
                  style={{ maxWidth: '60%' }}
                /> 
                
                */}

                <Typography variant='body2'
                  // sx={{
                  //    color: '#61dafb' 
                  //   color: '#ffff00'
                  // }}

                  gutterBottom>

                  "The very best idea can come from the <span style={{ color: '#ffff00' }}>quietest voice</span>.  Ideas are extremely <span style={{ color: '#ffff00' }}> fragile</span>.  Ideas are <span style={{ color: '#ffff00' }}> not predictable</span> in terms of <span style={{ color: '#ffff00' }}>when</span> you'll have them and <span style={{ color: '#ffff00' }}>how many</span> you are going to have." - Sir Jony Ive, Chief Design Officer, Apple

                </Typography>

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ backgroundColor: '#282c34', color: 'white' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{ color: 'red' }}
                  >
                    <Typography sx={{ width: '100%', flexShrink: 0, color: 'white', textAlign: 'center' }}>
                      <b>How we started</b>
                    </Typography>
                    {/* <Typography sx={{ color: 'text.secondary' }}>
                      Expand
                    </Typography> */}
                  </AccordionSummary>
                  <AccordionDetails >
                    <Typography variant='body2' component="span">
                      It all started with a question:&nbsp;
                    </Typography>
                    <Typography>
                      <b style={{ color: '#61dafb' }} >   "Why can't there be a better way?"</b>
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ backgroundColor: '#282c34', color: 'white' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{ color: 'red' }}
                  >
                    <Typography sx={{ width: '100%', flexShrink: 0, color: 'white', textAlign: 'center' }}>
                      <b>  Mission</b>
                    </Typography>
                    {/* <Typography sx={{ color: 'text.secondary' }}>
                      Expand
                    </Typography> */}
                  </AccordionSummary>
                  <AccordionDetails sx={{ color: 'white' }} >
                    <Typography>
                      <b style={{ color: '#61dafb' }} > co-create awareness and collaboratively nurture fragile ideas to uplift our community with sustainable outcomes</b>
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ backgroundColor: '#282c34', color: 'white' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{ color: 'red' }}
                  >
                    <Typography
                      sx={{ width: '100%', flexShrink: 0, color: 'white', textAlign: 'center' }}>
                      <b>  Why </b>
                    </Typography>
                  </AccordionSummary>


                  <AccordionDetails
                    sx={{ color: 'white' }}
                  >

                    <img
                      src={InertiaChange}
                      alt="Img"
                      style={{ maxWidth: '60%' }}
                    />

                    <Typography sx={{ marginTop: theme.spacing(0), padding: theme.spacing(1), wordWrap: 'break-word' }}>
                      <Typography variant='body2' gutterBottom>
                        For the <b style={{ color: '#61dafb' }}>Vanguards</b> and <b style={{ color: '#61dafb' }}>Doers</b> amongst us...
                      </Typography>
                      <Typography variant='body2' gutterBottom>
                        Imagine a world where it is always pyschologically safe to co-create awareness, where the time and energy required is substantially reduced,  where the underlying need for improvement can easily be shared and discussed, and the community's collective wisdom can be harnessed to collaboratively nurture fragile ideas towards fruition.
                      </Typography>
                      {/* <Typography variant='body2' gutterBottom>
                        Imagine a world where it is always pyschologically safe to co-create awareness, where the time and energy required is substantially reduced,  where the underlying need for change can easily be shared and discussed, and the community's collective wisdom can be harnessed to collaboratively nurture fragile ideas towards fruition.
                      </Typography> */}
                      {/* <Typography variant='body2' gutterBottom>
                        Imagine a world where the seed of potentially great ideas from the underserved are not drowned out by the status quo but instead flourish, beginning as a ripple and collaboratively nurtured from its fragile state towards fruition.
                      </Typography> */}
                      <Typography variant='body2' gutterBottom>
                        For those of us who are not content by being just another bystander/laggard, who strive for a life where we live amongst history's doers and builders who actually contribute to making a place far better because of our presence, let's come together to be the change-makers, the innovators, the dreamers who believe in the power of collaboration to uplift communities with sustainable outcomes. Carpe Diem!
                      </Typography>
                    </Typography>


                  </AccordionDetails>
                </Accordion>



                {/* 
                <Typography variant='body2' sx={{ color: '#61dafb' }} gutterBottom>
                  "A different world can not be built by indifferent people" - Peter Marshall
                </Typography>

                <Typography variant='body2' sx={{ color: '#61dafb' }} gutterBottom>
                  "Apathethic people are among the living dead" - Frank Sonnenberg
                </Typography>

                <Typography variant='body2' sx={{ color: '#61dafb' }} gutterBottom>
                  "Many people die at 25 and aren't buried until 75" - Benjamin Franklin
                </Typography> */}

                {/* <Typography variant='body2' sx={{ color: '#61dafb' }} gutterBottom>
                  "The very best idea can come from the quietest voice.  Ideas are extremely fragile.  Ideas are not predictable in terms of when you'll have them and how many you are going to have." - Sir Jony Ive, Chief Design Officer, Apple
                </Typography> */}


                {/* 
                <a
                  className="App-link"
                  // href="https://docs.google.com/forms/d/1C7mOtoNi7kMwx-lKZH0DDliVYfSBwggl4P1bMgiYB-0/edit?pli=1"
                  href="https://forms.gle/92ycEFb94KcKsvAn6"
                  target="_blank"
                  rel="noopener noreferrer"

                >
                  Sign up as a Beta User
                </a> */}


                <Typography
                  variant="body2"
                // sx={{ color: '#61dafb' }}
                >
                  Check out this Video from Dr. Christensen:
                </Typography>



                <Typography
                  variant="body1"
                  sx={{ color: '#61dafb' }}
                  component="a"
                  href="    https://www.linkedin.com/feed/update/urn:li:activity:7038095582862725121/"

                >
                  How Will You Measure Your Life?
                </Typography>







                <Accordion
                  expanded={expanded === 'panel4'}
                  onChange={handleChange('panel4')}
                  sx={{ backgroundColor: '#282c34', color: 'white' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{ color: 'red' }}
                  >
                    <Typography variant='body2' sx={{ width: '100%', flexShrink: 0, color: 'white' }}>
                      <i>  What is Goodwill Collaboration?</i>
                    </Typography>
                    {/* <Typography sx={{ color: 'text.secondary' }}>
                      Expand
                    </Typography> */}
                  </AccordionSummary>
                  <AccordionDetails sx={{ color: 'white' }} >

                    <Typography variant='body2' align='justify' gutterBottom>
                      <Typography variant='body2' sx={{ color: '#61dafb' }} component="span">
                        <b>  Goodwill collaboration </b>
                      </Typography>
                      refers to a collaborative effort or partnership that is based on mutual trust, respect, and a positive intention to work together towards a common goal or objective. It involves a genuine desire to cooperate, share ideas, and leverage each other's strengths in order to achieve a greater outcome or result, without any hidden agendas or ulterior motives.
                    </Typography>

                    <Typography variant='body2' align='justify' gutterBottom>
                      Goodwill collaboration is characterized by open communication, inclusivity, and a cooperative mindset, where all parties involved actively contribute, support each other, and strive for continuous improvement.


                    </Typography>
                    <Typography variant='body2' align='justify'  >
                      It is based on the belief that collaborative efforts built on goodwill can lead to more effective and sustainable solutions, fostering a positive environment for innovation, growth, and success.
                    </Typography>


                  </AccordionDetails>
                </Accordion>

                <Typography
                  variant="body1"
                  sx={{ color: '#61dafb' }}
                  component="a"
                  href="https://forms.gle/92ycEFb94KcKsvAn6"
                >
                  Sign up as a Beta User
                </Typography>


              </header>
            </div>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider >
  );
}

export default App;



